import PdfLayout from "./PdfLayout";

export default function Pdf() {
  return (
    <PdfLayout>
      <div className="pdf">
        <div className="wrapper">
          <div className="heading__overview">Company Overview:</div>
          <p>
            Go-Geeper is a one-stop agri-tech platform designed to redefine the
            agricultural sector in Nigeria by connecting smallholder farmers
            with resources, experts and markets nationwide. Our platform is
            designed to streamline every aspect of modern farming through
            advanced technology and offers a range of key features that help
            smallholder farmers to connect, grow and succeed.
          </p>
          <div className="heading__overview">Problem Statement:</div>
          <p>
            Smallholder farmers in Nigeria face a range of challenges, from
            access to markets and credit, to lack of information and expertise.
            These challenges make it tough for farmers to maximise yield and
            profitability. Go-Geeper addresses these challenges by providing
            farmers with access to advanced technology, expertise, and markets
            through a range of key features such as the marketplace, farm
            management advisory service, commodity linkage, food bank locator,
            and microlending.
          </p>
        </div>
      </div>
    </PdfLayout>
  );
}
