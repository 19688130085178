import PdfLayout from "./PdfLayout";

export default function Pdf2() {
  return (
    <PdfLayout>
      <div className="pdf">
        <div className="wrapper">
          <div className="heading__overview">Social Media:</div>
          <p>
            Be sure to follow us on our social media channels to stay up-to-date
            on the latest Go-Geeper news, events, and developments.
          </p>
          <ul>
            <li>Instagram: Go-Geeper</li>
            <li>LinkedIn: Go-Geeper</li>
          </ul>
        </div>
      </div>
    </PdfLayout>
  );
}
