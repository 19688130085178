import PdfLayout from "./PdfLayout";

export default function Pdf3() {
  return (
    <PdfLayout>
      <div className="pdf">
        <div className="wrapper">
          <div className="heading__overview">Contact Information:</div>
          <ul>
            <li>
              For press inquiries, please contact [Name], [Position] at [Email]
              or [Phone number].
            </li>
            <li>
              For general inquiries, please visit our website at [insert link]
              or email us at [insert email].
            </li>
          </ul>
        </div>
      </div>
    </PdfLayout>
  );
}
