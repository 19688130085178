import PdfLayout from "./PdfLayout";

export default function Pdf1() {
  return (
    <PdfLayout>
      <div className="pdf">
        <div className="wrapper">
          <div className="heading__overview">Solution:</div>
          <p>
            Go-Geeper's platform includes a range of key features such as farm
            management advisory service, marketplace, commodity linkage, food
            bank locator, and microlending. These features are designed to help
            farmers connect with resources, experts, and markets to increase
            efficiency, profitability, and sustainability.
          </p>
          <ul>
            <li>
              The <b>marketplace</b> feature ensures that buyers have access to
              safe, traceable and healthy produce from local farms. Smallholder
              farmers also benefit from being connected with a wide range of
              industry partners, from seed and equipment suppliers to buyers and
              processors, on a nationwide scale, allowing farmers to easily sell
              more of their produce and expand their reach to new markets and
              more customers.
            </li>
            <li>
              The <b>farm management advisory as a service (FAMaaS™)</b> is
              tailored to provide on-demand expert guidance and customised
              solutions that can help farmers overcome the challenges they face
              in Nigeria and also position them to better adapt to climate
              change impact.
            </li>
            <li>
              The <b>commodity linkage</b> feature uses real-time data to help
              smallholder farmers and other actors across the agricultural value
              chain track market trends and identify the best buyers and
              opportunities for their products, ensuring that farmers are
              getting the most value for their produce and staying abreast of
              market dynamics.
            </li>
            <li>
              The <b>food bank locator</b> service makes it easy for farmers to
              donate produce to local food banks and support their communities.
              Also, individuals in need can easily locate the nearest food in
              their area.
            </li>
            <li>
              The <b>microlending</b> feature provides smallholder farmers
              within the Go-Geeper ecosystem access to financial and insurance
              services.
            </li>
          </ul>
        </div>
      </div>
    </PdfLayout>
  );
}
