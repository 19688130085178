import logo from "../../Assets/colored__logo.png";
import "./pdf.css";

export default function PdfLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="pdf__layout">
      {" "}
      <div className="image__top__banner">
        <div className="image">
          <img src={logo} alt="" />
        </div>
        <div className="top__banner"></div>
      </div>
      {children}
      <div className="bottom__banner"></div>
      <div className="footer">
        <ul>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.78125 7.79953C6.29576 8.86204 7.15492 9.719 8.21875 10.2308C8.29722 10.2679 8.38402 10.284 8.47059 10.2774C8.55717 10.2709 8.64054 10.2419 8.7125 10.1933L10.275 9.14953C10.344 9.10271 10.4238 9.07414 10.5069 9.06649C10.5899 9.05884 10.6736 9.07236 10.75 9.10578L13.675 10.362C13.775 10.4036 13.8585 10.477 13.9127 10.5707C13.9669 10.6644 13.9888 10.7734 13.975 10.8808C13.8823 11.6044 13.5291 12.2694 12.9815 12.7514C12.4339 13.2334 11.7295 13.4994 11 13.4995C8.74566 13.4995 6.58365 12.604 4.98959 11.0099C3.39553 9.41588 2.5 7.25387 2.5 4.99953C2.50016 4.27003 2.76613 3.56558 3.24814 3.018C3.73015 2.47043 4.39516 2.11724 5.11875 2.02453C5.22615 2.01074 5.3351 2.03263 5.42884 2.08684C5.52258 2.14105 5.59589 2.22456 5.6375 2.32453L6.89375 5.25578C6.92642 5.331 6.94008 5.4131 6.93354 5.49484C6.92701 5.57658 6.90046 5.65547 6.85625 5.72453L5.8125 7.31203C5.76603 7.38383 5.73876 7.46635 5.73329 7.55171C5.72782 7.63706 5.74433 7.72239 5.78125 7.79953Z"
                stroke="#26925F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>+234 0000 0000</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                stroke="#26925F"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M2.34375 6H13.6562"
                stroke="#26925F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.34375 10H13.6562"
                stroke="#26925F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 13.8381C9.38071 13.8381 10.5 11.2245 10.5 8.00059C10.5 4.77662 9.38071 2.16309 8 2.16309C6.61929 2.16309 5.5 4.77662 5.5 8.00059C5.5 11.2245 6.61929 13.8381 8 13.8381Z"
                stroke="#26925F"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
            </svg>
            <span>www.gogeeper.com</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M14 3.5L8 9L2 3.5"
                stroke="#26925F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 3.5H14V12C14 12.1326 13.9473 12.2598 13.8536 12.3536C13.7598 12.4473 13.6326 12.5 13.5 12.5H2.5C2.36739 12.5 2.24021 12.4473 2.14645 12.3536C2.05268 12.2598 2 12.1326 2 12V3.5Z"
                stroke="#26925F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.90625 8L2.15625 12.3562"
                stroke="#26925F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.8438 12.3562L9.09375 8"
                stroke="#26925F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>contact@gogeeper.com</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
