// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  box-sizing: border-box; 
  margin: 0;
  padding: 0
}



:root {
  --primary-color:#4CA30D;
   --secondary-color: #667085;
   --font-inter: 'Inter', sans-serif;
   --ui-gray: #F9FAFB;
   --bg-blur: #F3FEE7;
   --text-color: #667085 ;

}


.styled {
  display: 'none !important';
}

body {
  
  font-family: var(--font-inter) !important;
  overflow-x: hidden !important;
  
}



.asterisk::after{

  content: '*' ;
  color: red;

}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,sBAAsB;EACtB,SAAS;EACT;AACF;;;;AAIA;EACE,uBAAuB;GACtB,0BAA0B;GAC1B,iCAAiC;GACjC,kBAAkB;GAClB,kBAAkB;GAClB,sBAAsB;;AAEzB;;;AAGA;EACE,0BAA0B;AAC5B;;AAEA;;EAEE,yCAAyC;EACzC,6BAA6B;;AAE/B;;;;AAIA;;EAEE,aAAa;EACb,UAAU;;AAEZ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap\" );\n*{\n  box-sizing: border-box; \n  margin: 0;\n  padding: 0\n}\n\n\n\n:root {\n  --primary-color:#4CA30D;\n   --secondary-color: #667085;\n   --font-inter: 'Inter', sans-serif;\n   --ui-gray: #F9FAFB;\n   --bg-blur: #F3FEE7;\n   --text-color: #667085 ;\n\n}\n\n\n.styled {\n  display: 'none !important';\n}\n\nbody {\n  \n  font-family: var(--font-inter) !important;\n  overflow-x: hidden !important;\n  \n}\n\n\n\n.asterisk::after{\n\n  content: '*' ;\n  color: red;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
